


















































































































































import { gql } from "apollo-server-lambda";
import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue";
import Breweries from "@/components/add-brewery.vue";
import BatchList from "@/components/batches.vue";
import { DEVICE_BY_ID, deviceAllocateToBrewery } from "@/graphql/Device";
import { breweryAllocateDevice } from "@/graphql/Brewery";
export const AllocateDevice = gql`
  mutation AllocateDevice(
    $deviceId: ID!
    $breweryId: ID!
    $checklist: [String!]!
  ) {
    allocateDevice(
      breweryId: $breweryId
      deviceId: $deviceId
      checklist: $checklist
    ) {
      name
      id
    }
  }
`;

export const DeallocateDevice = gql`
  mutation DeallocateDevice($deviceId: ID!, $comment: String!) {
    deallocateDevice(deviceId: $deviceId, comment: $comment) {
      name
      id
    }
  }
`;

@Component({
  components: {
    Login,
    Breweries,
    BatchList,
  },
  computed: {},
  data: () => ({
    selected: null,
    allocated: true,
    device: null,
    DEVICE_BY_ID,
  }),
  methods: {
    async allocateDevice(breweryId, device) {
      console.log("allocate device", device, {
        deviceId: device.id,
        breweryId,
      });

      const r = await this.$apollo.mutate({
        mutation: AllocateDevice,
        variables: {
          deviceId: this.$route.params.id,
          breweryId,
          checklist: [],
        },
        update: (store, { data: { allocateDevice }, errors }: any) => {
          if (allocateDevice) {
            let id = this.$route.params.id;
            deviceAllocateToBrewery(store, id, allocateDevice);
            breweryAllocateDevice(store, breweryId, (x: any) => {
              x.push({
                __typename: "Device",
                id: device.id,
                name: device.name,
              });
              return x;
            });
          } else {
            alert("Failed to allocate");
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          allocateDevice: {
            __typename: "Brewery",
            id: breweryId,
            name: "Saving...",
          },
        },
      });
      console.info("result", r, this);
    },
    async deallocateDevice(breweryId, deviceId) {
      console.log("deallocate device", {
        deviceId,
      });

      const r = await this.$apollo.mutate({
        mutation: DeallocateDevice,
        variables: {
          deviceId,
          comment: "",
        },
        update: (store, { data: { deallocateDevice }, errors }: any) => {
          if (deallocateDevice) {
            deviceAllocateToBrewery(store, this.$route.params.id, null);
            breweryAllocateDevice(store, breweryId, (x: any) =>
              x.filter((y: any) => y.id !== deviceId)
            );
          } else {
            alert("Failed to remove");
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          deallocateDevice: {
            __typename: "Device",
            id: -1,
            name: "Removing...",
          },
        },
      });

      console.info("result", r, this);
    },
  },
})
export default class Home extends Vue {}
