var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":['startTime'],"sort-desc":[true],"items-per-page":20,"footer-props":{
      itemsPerPageOptions: [20, 30, 50, -1],
    }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"plain":"","to":'/batch/' + item.id}},[_vm._v(" "+_vm._s(item.name.length > 20 ? ((item.name.substring(0, 20)) + "...") : item.name)+" ")])]}},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s({ BREWING: "Brewing", ENDED: "Finished", }[item.state])+" ")])]}},{key:"item.device.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"purple","to":'/device/' + item.device.id}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.vessel.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary","to":'/vessel/' + item.vessel.id}},[_vm._v(" "+_vm._s(item.vessel.name)+" ")])]}},{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.startTime ? new Date(item.startTime).toLocaleDateString() : "---")+" ")]}},{key:"item.endTime",fn:function(ref){
    var item = ref.item;
return [_c('span',{attrs:{"title":item.endTime}},[_vm._v(" "+_vm._s(item.endTime ? new Date(item.endTime).toLocaleDateString() : "still brewing"))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }