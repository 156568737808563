import gql from "graphql-tag";

export const deviceAllocateToBrewery = (store, id, brewery) => {
  console.log("deviceAllocateToBrewery read", id, brewery);

  try {
    let { devices } = store.readQuery({
      query: DEVICES,
    });
    if (devices) {
      devices = devices.map((x) => {
        if (x.id == id) {
          x.brewery = brewery;
        }
        return x;
      });
      store.writeQuery({
        query: DEVICES,
        data: { devices },
      });
    }
  } catch (error) {
    console.debug("deviceAllocateToBrewery2", error);
  }

  try {
    const { deviceById } = store.readQuery({
      query: DEVICE_BY_ID,
      variables: {
        id,
      },
    });

    if (deviceById) {
      deviceById.brewery = brewery;
      store.writeQuery({
        query: DEVICE_BY_ID,
        variables: {
          id,
        },
        data: { deviceById },
      });
    }
  } catch (error) {
    console.debug("deviceAllocateToBrewery", error);
  }
};

export const DEVICE_BY_ID = gql`
  query DeviceById($id: ID!) {
    deviceById(id: $id) {
      name
      id      
      isOnline
      isMonitoring
      brewery {
        name
        id
      }
      monitoring {
        name
        id
        state
        startTime
        endTime
        measurements {
          value
          unit {
            name
            abbreviation
          }
        }
        vessel {
          name
          id
        }
      }
    }
  }
`;

export const DEVICES = gql`
  query {
    devices {
      id
      name
      serialNumber
      thingsBoardId
      createdTime
      lastActive
      stats {
        batchCountTotal
        batchDurationTotal
      }
      brewery {
        name
        id
      }
      location {
        company
        place
      }
    }
  }
`;
