






































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["items"],
  computed: {
    batches() {
      return (this as any).items;
    },
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        width: 100,
      },
      { text: "Zymometer", value: "device.name" },
      { text: "Vessel", value: "vessel.name" },
      { text: "Started", value: "startTime" },
      { text: "Ended", value: "endTime" },
      { text: "Status", value: "state" },
    ],
  }),
  methods: {
    getColor(status) {
      if (status == "ENDED") return "green";
      else if (status == "ERROR") return "red";
      else return "blue";
    },
  },
})
export default class Breweries extends Vue {}
