var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(!_vm.$store.state.okta_token)?_c('Login'):_vm._e(),(_vm.$store.state.okta_token)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ApolloQuery',{attrs:{"query":_vm.DEVICE_BY_ID,"variables":{ id: this.$route.params.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "),_c('pre',[_vm._v(_vm._s(error))])]):(data)?_c('div',{staticClass:"result apollo"},[_c('v-card',{staticClass:"mx-auto mb-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(data.deviceById.name))]),_c('v-card-subtitle',{staticClass:"mt-2"},[(data.deviceById.brewery)?_c('v-btn',{attrs:{"small":"","outlined":"","to":'/brewery/' + data.deviceById.brewery.id}},[_vm._v(_vm._s(data.deviceById.brewery.name)+" ")]):_vm._e(),(data.deviceById.brewery)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","title":("Deallocate device from " + (data.deviceById.brewery.name)),"outlined":"","color":"red"},on:{"click":function (x) { return _vm.deallocateDevice(
                            data.deviceById.brewery.id,
                            data.deviceById.id
                          ); }}},[_c('v-icon',[_vm._v("mdi-home-edit")])],1):_vm._e(),(!data.deviceById.brewery)?_c('ApolloQuery',{attrs:{"query":require('../graphql/Breweries.gql'),"variables":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var ref_result = ref.result;
                          var error = ref_result.error;
                          var p = ref_result.data;
                          var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v(" Loading... ")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "),_c('pre',[_vm._v(_vm._s(error))])]):(p)?_c('div',{staticClass:"result apollo"},[_c('v-select',{attrs:{"items":p.breweries,"item-text":"name","item-value":"id","label":"Allocate to brewery","outlined":""},on:{"change":function (x) { return _vm.allocateDevice(x, data.deviceById); }}})],1):_c('div',{staticClass:"no-result apollo"},[_vm._v(" No result :( ")])]}}],null,true)}):_vm._e(),(data.deviceById.monitoring)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"purple","to":'/batch/' + data.deviceById.monitoring.id}},[_vm._v(_vm._s(data.deviceById.monitoring.name))]):_vm._e(),(data.deviceById.monitoring)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"blue","to":'/vessel/' + data.deviceById.monitoring.vessel.id}},[_vm._v(_vm._s(data.deviceById.monitoring.vessel.name))]):_vm._e()],1)],1)],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}],null,false,12794313)}),_c('ApolloQuery',{attrs:{"query":require('../graphql/BatchesByDeviceId.gql'),"variables":{ id: this.$route.params.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var ref_result = ref.result;
                          var error = ref_result.error;
                          var b = ref_result.data;
                          var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading... A")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "),_c('pre',[_vm._v(_vm._s(error))])]):(b)?_c('div',{staticClass:"result apollo"},[_c('BatchList',{attrs:{"items":b.batchesByDeviceId}})],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}],null,false,331380837)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }