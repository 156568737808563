import gql from "graphql-tag";

export const breweryAllocateDevice = (store, id, modify) => {
  console.log("breweryAllocateDevice read", id, modify);
  const data = store.readQuery({
    query: BREWERY_BY_ID,
    variables: {
      id,
    },
  });
  data.breweryById.devices = modify(data.breweryById.devices);
  store.writeQuery({
    query: BREWERY_BY_ID,
    variables: {
      id,
    },
    data,
  });
};

export const BREWERY_BY_ID = gql`
  query BreweryById($id: ID!) {
    breweryById(id: $id) {
      name
      id
      devices {
        name
        id
      }
      vessels {
        name
        id
        totalVolume {
          value
          unit
        }
        netVolume {
          value
          unit
        }
      }
    }
  }
`;
