<template>
  <form>
    <v-card class="mx-auto" max-width="500">
      <v-card-title>Create Brewery</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Name"
          required
          :disabled="loading"
        ></v-text-field>
        <!-- <v-select
          v-model="select"
          item-text="label"
          item-value="value"
          :items="[
            { value: 1, label: 'Zymometer 1' },
            { value: 2, label: 'Zymometer 2' },
            { value: 3, label: 'Zymometer 3' },
          ]"
          :disabled="loading"
          label="Devices"
          required
          multiple
        ></v-select
      > -->
      </v-card-text>
      <v-card-actions center>
        <v-btn color="primary" @click="create" outlined block
          >Add Brewery</v-btn
        >
      </v-card-actions>
    </v-card>
  </form>
</template>

<script lang="js">
import { Component, Vue } from "vue-property-decorator";

import TASKS_ALL from "../graphql/AddBrewery.gql";
import BREWERIES from "../graphql/Breweries.gql";
@Component({
  components: {},
  computed: {
    assets() {
      return this.$store.state.assets;
    },
  },
  data: () => ({
    selected: null,
    loading:false,
    name: "",
    select: "",
    newTag: "",
    nameErrors: "",
    selectErrors: "",
  }),
  methods: {
    async create() {
      const name = this.name;
      console.log('selected',this.select)
      this.loading=true
      try {
        const r = await this.$apollo.mutate({
          mutation: TASKS_ALL,
          variables: {
            name: name,
          },
          update: (store, { data: { id } }) => {
       if (id) {
         if (this.type === "private") {
           const data = store.readQuery({
             query: BREWERIES
           });
         }
       }
     },
          optimisticResponse: {
            __typename: "Mutation",
            createBrewery: {
              __typename: "Brewery",
              id: -1,
              name: name,
            },
          },
        });
        this.name = "";
        this.select = "";
        const { data } = r
        console.debug('ddd -> ',r)

        if (data) {
          console.log('r',data);
          alert(
            "Success! " +
              data.createBrewery.name +
              " id:" +
              data.createBrewery.id
          );
        }
      } catch (error) {
          console.debug("DATA", error);
          console.error("FAILED2", error);
          alert(error);
      }
      this.loading=false

    },
  },
})
export default class Breweries extends Vue {}
</script>
